import classes from "./PrimaryBanner.module.css";
import React, { useContext, useEffect, useState, memo } from "react";
import AuthContext from "../../store/auth-context";
import { t } from "../../locales/i18n";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import { NAIL_SHOP_INFO } from "../../utils/Const";

const PrimaryBanner = (props) => {
  const {} = props || {};
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [banner, setBanner] = useState(images.nail7);

  // useEffect(() => {
  //   const primary_banner = RemoteConfig.getConfigValue("primary_banner");
  //   primary_banner && setBanner(primary_banner);
  // }, [isFetchActivateRemoteConfig]);

  return (
    <div className={classes.primaryBanner}>
      <div className={classes.textContainer}>
        <h2>{"YOUR NAILS\n       YOUR STYLE"}</h2>
        <h3 className={classes.textDescription}>
          {
            "A talented team of Nail Specialists is here to deliver a top-notch treatment experience tailored to you.\nWith extensive training and a sharp focus on detail, every element of your nail care will be executed with expertise and care.\n\nFrom helping you select the ideal shade to providing personalized design guidance,\nwe're committed to making your visit nothing short of exceptional."
          }
        </h3>
        <BookNowBtn
          onClick={() => window.open("tel:" + NAIL_SHOP_INFO.tel, "_self")}
          style={classes.callNowBtn}
          title={"CALL NOW"}
        />
      </div>

      <div className={classes.imgContainer}>
        <img
          className={classes.primaryBannerImage}
          alt={"Primary Banner"}
          src={banner}
        />
      </div>
    </div>
  );
};

export default memo(PrimaryBanner);
