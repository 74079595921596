import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./ContactUs.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "../../locales/i18n";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";
import BookNowBtn from "../../components/BookNowBtn";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { ReactPhotoCollage } from "react-photo-collage";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import '../../index.css'; 

const ContactUs = () => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Contact us"}</title>
        <meta name="contact-us" content="Contact us" />
      </Helmet>
      <Header selected={"contact-us"} />

      <div className={classes.contactUsWrapper}>
        <h3>
          If you have questions, comments, or other feedback related to our
          services, please contact us to discuss.
        </h3>

        <div className={classes.rowInfo}>
          <div className={classes.columnInfo}>
            <h2>Contact Details</h2>
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${NAIL_SHOP_INFO.ggMapUrl}`}
            >
              {NAIL_SHOP_INFO.address}
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${NAIL_SHOP_INFO.tel}`}
            >
              {NAIL_SHOP_INFO.phone}
            </a>

            <div style={{ flexDirection: "row" }}>
              <a
                target={"_blank"}
                href={NAIL_SHOP_INFO.instagram}
                rel="noreferrer"
              >
                <img
                  className={classes.contactsContainerImg}
                  alt={"insta"}
                  g
                  src={images.instaIcon}
                />
              </a>
              <a
                target={"_blank"}
                href={NAIL_SHOP_INFO.facebook}
                rel="noreferrer"
              >
                <img
                  className={`${classes.contactsContainerImg} ${classes.marginLeftFBIcon}`}
                  alt={"facebook"}
                  src={images.fbIcon}
                />
              </a>
            </div>
            <BookNowBtn style={`${classes.bookNowBtnStyl}`} />

          </div>

          <div className={classes.columnInfo}>
            <h2>Opening Hours</h2>
            <h4>
              {`${NAIL_SHOP_INFO.openingTime.openTime.weekdays}: ${NAIL_SHOP_INFO.openingTime.openTime.time}`}
            </h4>
            <h4>
              {`${NAIL_SHOP_INFO.openingTime.closeTime.weekdays}: ${NAIL_SHOP_INFO.openingTime.closeTime.time}`}
            </h4>

            <h4>
              If you enjoyed your visit please kindly leave us a review. Thank
              you!
            </h4>
            <button
              onClick={() => window.open(NAIL_SHOP_INFO.ggMapUrl, "_blank")}
              className={`${classes.btn} linear-gradient-bg`}
            >
              <h3>REVIEW US ON GOOGLE</h3>
            </button>
          </div>
        </div>

        <iframe
        
          title="google-map"
          className={classes.iframe}
          src={NAIL_SHOP_INFO.ggMapIframeUrl}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <FooterInfo style={classes.footerStyle} />
      <FloatingButton />
    </div>
  );
};

export default ContactUs;
